import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import PublicLayout from './components/layout/PublicLayout';
import NotFoundPage from './components/pages/NotFoundPage';
import LoginPage from './components/pages/Auth/Login/LoginPage';
import RegisterPage from './components/pages/Auth/Register/RegisterPage';
import LoginLayout from './components/layout/LoginLayout';
import ResetPasswordPage from './components/pages/Auth/ResetPassword/ResetPasswordPage';
import OrderHistoryPage from './components/pages/OrderHistory/OrderHistoryPage';
import Settings from './components/pages/Settings/Settings';
import HomePage from './components/pages/HomePage/HomePage';
import Contact from './components/pages/Contact';
import PaymentPage from './components/pages/Payment/PaymentPage';
import Admin from './components/pages/Admin/Admin';
import { useAuth } from './context/AuthContext';
import { WebSettingsProvider } from './context/WebSettingsContext';
import InspectionReport from './components/pages/Inspection';

const AnimatedRoutes = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<PublicLayout />}>
          <Route index element={<HomePage />} />
          <Route path="/order-history" element={<OrderHistoryPage />} />
          <Route path="/settings/*" element={<Settings />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/contact" element={<Contact />} />
        </Route>
        <Route path="/admin" element={<PublicLayout isAdmin={true} />}>
          <Route index element={<Admin />} />
          <Route path="/admin/*" element={<Admin />} />
        </Route>
        <Route path="/" element={<LoginLayout />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
          <Route path="/payment" element={<PaymentPage />} />
        </Route>
        <Route path="/inspection" element={<InspectionReport />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </AnimatePresence>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <WebSettingsProvider>
        <AnimatedRoutes />
      </WebSettingsProvider>
    </Router>
  );
};

export default App;
