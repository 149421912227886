import React from 'react';

const Tarkastuskertomus = () => {
  return (
    <>
      <style>
        {`
          .report-container {
            max-width: 800px;
            margin: auto;
            padding: 20px;
            font-family: Arial, sans-serif;
            background-color: #f9f9f9;
            border: 1px solid #ccc;
            border-radius: 8px;
          }

          .report-header {
            text-align: center;
            margin-bottom: 20px;
          }

          .report-header h1 {
            font-size: 24px;
            font-weight: bold;
            margin: 0;
          }

          .report-header h2 {
            font-size: 18px;
            margin: 10px 0 0;
          }

          .report-section {
            margin-bottom: 20px;
          }

          .report-section h3 {
            font-size: 18px;
            font-weight: bold;
            border-bottom: 1px solid #ccc;
            padding-bottom: 5px;
            margin-bottom: 10px;
          }

          .report-section ul {
            list-style-type: none;
            padding-left: 0;
          }

          .report-section ul li {
            margin-bottom: 8px;
          }

          .report-footer {
            margin-top: 20px;
            border-top: 1px solid #ccc;
            padding-top: 10px;
            font-size: 14px;
            text-align: center;
          }

          .report-footer a {
            color: #007BFF;
            text-decoration: none;
          }

          .report-footer a:hover {
            text-decoration: underline;
          }
        `}
      </style>
      <div className="report-container">
        <div className="report-header">
          <h1>Tarkastuskertomus</h1>
          <h2>Alanya Kebab</h2>
        </div>

        <div className="report-section">
          <p>
            <strong>Sijainti:</strong> Torikuja 2, 11100 RIIHIMÄKI
          </p>
          <p>
            <strong>Tarkastuspäivä:</strong> 23.10.2024
          </p>
          <p>
            <strong>Tarkastaja:</strong> Anne Huhtala
          </p>
        </div>

        <div className="report-section">
          <h3>Havainnot</h3>
          <ul>
            <li>
              <strong>Omavalvonta:</strong> Oivallinen
            </li>
            <li>
              <strong>Tilojen ja pintojen puhtaus:</strong> Oivallinen
            </li>
            <li>
              <strong>Tilojen ja välineiden kunnossapito:</strong> Oivallinen
            </li>
            <li>
              <strong>Henkilökunnan hygienia:</strong> Oivallinen
            </li>
            <li>
              <strong>Elintarvikkeiden käsittely:</strong> Oivallinen
            </li>
            <li>
              <strong>Lämpötilojen hallinta:</strong> Oivallinen
            </li>
            <li>
              <strong>Myynti ja tarjoilu:</strong> Oivallinen
            </li>
            <li>
              <strong>Allergioiden hallinta:</strong> Oivallinen
            </li>
            <li>
              <strong>Elintarviketiedot:</strong> Oivallinen
            </li>
          </ul>
        </div>

        <div className="report-section">
          <h3>Suositukset</h3>
          <p>
            Omavalvontasuunnitelmaa tulisi päivittää vastaamaan nykyisiä lainsäädännön vaatimuksia.
            Lämpötilakirjaukset ja allergeenien hallinta tarkistetaan seuraavan tarkastuksen
            yhteydessä.
          </p>
        </div>

        <div className="report-section">
          <h3>Raportin saatavuus</h3>
          <p>
            Raportti on nähtävillä ravintolan sisäänkäynnin läheisyydessä. Se julkaistaan verkossa{' '}
            <a href="https://www.oivahymy.fi" target="_blank" rel="noopener noreferrer">
              www.oivahymy.fi
            </a>{' '}
            viimeistään 23.1.2025.
          </p>
        </div>

        <div className="report-footer">
          <p>Tarkastajan yhteystiedot: anne.huhtala@riihimaki.fi | +358505090455</p>
          <p>Tapahtumatunnus: 1797293</p>
        </div>
      </div>
    </>
  );
};

export default Tarkastuskertomus;
